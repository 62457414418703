import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestData } from 'src/app/common/models/request-data.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LuckyDrawWinnerService {
  
  constructor(private http: HttpClient) { }
    
  luckyDrawWinnerAPI=[
    {
      status:"SUCCESS",
      message:"",
      data:{
         winners:[
            {
              id:1,
              customer_name:"jojesh jose",
              customer_mail:"jojesh@gmail.com",
              customer_phone:"9496981502",
              customer_type:1,
              receipt_number:"S075A-101-0023",
              reward_name:"Mochi Tea",
              winner_status:1,
              draw_status:1
            },
            {
              id:2,
              customer_name:"jesty david",
              customer_mail:"jest.mojosoft@gmail.com",
              customer_phone:"1321311",
              customer_type:0,
              receipt_number:"123215-12-123546",
              reward_name:"Mochi Tea",
              winner_status:1,
              draw_status:1
            },
            {
              id:3,
              customer_name:"jesty david",
              customer_mail:"jest.mojosoft@gmail.com",
              customer_phone:"1321311",
              customer_type:0,
              receipt_number:"123215-12-123546",
              reward_name:"Mochi Tea",
              winner_status:1,
              draw_status:1
            },
            {
              id:4,
              customer_name:"jesty david",
              customer_mail:"jest.mojosoft@gmail.com",
              customer_phone:"1321311",
              customer_type:0,
              receipt_number:"123215-12-123546",
              reward_name:"Mochi Tea",
              winner_status:1,
              draw_status:1
            },
            {
              id:5,
              customer_name:"jojesh jose",
              customer_mail:"jojesh@gmail.com",
              customer_phone:"9496981502",
              customer_type:1,
              receipt_number:"S075A-101-0023",
              reward_name:"Mochi Tea",
              winner_status:1,
              draw_status:1
            },
            {
              id:6,
              customer_name:"jojesh jose",
              customer_mail:"jojesh@gmail.com",
              customer_phone:"9496981502",
              customer_type:1,
              receipt_number:"S075A-101-0023",
              reward_name:"Mochi Tea",
              winner_status:0,
              draw_status:1
            },
         ],
         total_count:6
      },
      serverTime:"2024-09-01 12:00",
      token:""
   }
  ]





  getLuckyDrawWinnerDetails(requestData:RequestData): Observable<any> {
    // return of(this.luckyDrawWinnerAPI);
    //  return of(this.luckyDrawWinnerAPI[0]); 
     return this.http.post(environment.baseUrl+'/lucky-draw/get-draw-winners',requestData);
     
  }

  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

  updateWinnerStatus(winnerInfo: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/lucky-draw/update-winner-status', winnerInfo);


  }
  
}
