import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { FloatingMenuOption } from "src/app/common/components/master-floating-menu/model/master-floating-model";
import { OrderModel } from "./model/order.model";
import { OrderService } from "./order.service";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { PermissionService } from "src/app/auth/permission.service";
import {
  ColumnFilter,
  RequestData,
  SearchSort,
} from "src/app/common/models/request-data.model";
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/common/components/confirm-dialog/confirm-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import {
  FilterListItemModel,
  SearchFilterComponent,
  SearchParam,
} from "src/app/common/components/search-filter/search-filter.component";
import { Router, ActivatedRoute } from "@angular/router";
import { SystemSettingsService } from "src/app/common/services/system-settings.service";
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: "app-orders",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent {
  orderList: OrderModel[]=[];
  showSearch: boolean = false;
  hideAdvancedFilter: boolean = false;
  isLoading = true;
  filter: FilterListItemModel[] = [
    {
      title: "Order Date",
      column: "order_time",
    },

    {
      title: "Shop Name",
      column: "shop.name",
    },
    {
      title: "Order Id",
      column: "order_id",
    },
    {
      title: "QTY",
      column: "total_qty",
      type: "boolean",
    },
    {
      title: "Total Amount",
      column: "total_amount",
      type: "boolean",
    },
  ];

  displayedColumns = [
    'item',
    "order_time",
    "shop_name",
    "order_id",
    "total_qty",
    "total_amount",
  ];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true,
  };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: OrderModel;
  showRewardCategorySummary: boolean = false;
  selection = new SelectionModel<OrderModel>(true, []);
  dateTimeFormat: string;
  dateFormat: string;
  defaultCustomerFilter = [{ column: "crm_customer_id", operator: "=", value: "" }];
  customerId: any;
  dataSource = new MatTableDataSource(this.orderList);
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  permission: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private orderService: OrderService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private systemSettingService: SystemSettingsService,
    private router: Router,private stateManagerService: StateManagerService,
    private permissionService:PermissionService
    
  ) {
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.orderList = [];
    this.permission = permissionService.getPagePermission('order');

  
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.customerId = this.route.snapshot.params['custid'];
    if (this.customerId != '' && this.customerId != undefined) {
      this.hideAdvancedFilter = true;
      this.defaultCustomerFilter[0].value = this.customerId;
      this.menuOption.search = true;
    } else {
      this.menuOption.search = false;
      this.hideAdvancedFilter = false;
    }

    

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
      });
   // this.loadData();
    // this.route.queryParams.subscribe((params) => {
    //   var custId = undefined;
    //   // Check if the 'custid' parameter is present
    //   if (params["custid"]) {
    //     custId = params["custid"];
    //     // Check if custId is present and show/hide the button accordingly
    //     if (custId) {
    //       this.customerId = custId;
    //       // Show the button
    //       this.menuOption.search = true;
    //     } else {
    //       // Hide the button
    //       this.menuOption.search = false;
    //     }
    //   }
    //   this.isLoading = false; // Set to true on page load

    //   this.loadData();
    // });
  }
    /** 
  * Set the datasource
  */
    setDataSource(orderList:OrderModel[]){
      this.dataSource=new MatTableDataSource(orderList);
      this.dataSource.sort=this.sort;
    }
  
    @ViewChild(MatSort) sort!: MatSort;
  ngAfterViewInit(): void {
    if (this.customerId != '' && this.customerId != undefined) {

      // this.hideAdvancedFilter=true;
      //  this.defaultCustomerFilter[0].value =this.customerId;
      this.searchFilter.setColumnFilter(this.defaultCustomerFilter);
    }
    this.loadPreviousState();
  }
  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('order_time','asc');
     // this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.orderList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.orderList);
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }
  getTitle(): string {
    const customerIdQueryParam = this.route.snapshot.queryParams['custid'];

    if (customerIdQueryParam) {
      // If there is a customerId in the query parameters, find the corresponding order
      const selectedOrder = this.orderList.find(order => order.crm_customer_id == customerIdQueryParam);

      if (selectedOrder) {
        // If the order is found, include customer details in the title
        const customerDetails = selectedOrder.customer_dtl && selectedOrder.customer_dtl.length > 0
          ? selectedOrder.customer_dtl[0]
          : null;

        const customerName = customerDetails ? `${customerDetails.first_name} ${customerDetails.last_name}` : "Unknown Customer";

        return `Orders of : ${customerName}`;
      }
    }

    // Default title for a new customer
    return "Orders";
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: this.pagingData.pageIndex * this.pagingData.pageSize,
          },
        },
        sort: this.getSort(),
      },
    };

    return requestData;
  }
  /**
   * Internal filters 
   * @param custId 
   * @returns 
   */
  buildCustomRequestData(custId: any): any {
    return { search: { filters: { simpleSearch: [], advSearch: [{ column: "crm_customer_id", operator: "=", value: custId }], scope: { limit: 10, offset: 0 } }, sort: [] } }

  }

  /**
   * loads the order data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    var requestData: RequestData;
    requestData = this.buildRequestData();

    // if (custId !== undefined) {
    //   if (requestData.search !== undefined && requestData.search.filters !== undefined && requestData.search?.filters?.advSearch == undefined) {
    //     requestData.search.filters.advSearch = [];
    //   }
    //   requestData.search?.filters?.advSearch?.push({ column: "crm_customer_id", operator: "=", value: custId });

    // }

    let response = this.orderService
      .getOrderList(requestData)
      .subscribe((response) => {
        if (response.status == "SUCCESS") {
          const newOrderList = response.data.orders;
          if (JSON.stringify(newOrderList) !== JSON.stringify(this.orderList)) {
            this.setDataSource(newOrderList);
            this.orderList = newOrderList; // Update local copy if needed
          }
          this.pagingData.length = response.data.total_count;
          if (this.pagingData.length == 0) {
            let snackBarData: SnackBarData = {
              message:
                "No data found. Please remove or change the filter if any.",
              title: "No Data!!!",
              type: "warn",
            };
            this.snackBarService.openSnackBar(snackBarData);
            this.selectedItem = undefined;
          } else {
            this.onRowSelected(this.orderList[0]);
            this.stateManagerService.setState(this.constructor.name, requestData);
          }

        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
      });
  }


  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  /**
   * On Add clicked
   * Show the dialog to enter new category
   */
  addClicked(): void {
    //this.router.navigate(["reward-category-edit", 0]);
  }
  navBack(): void {
    this.router.navigate(["/customer"]);
  }
  /**
   *
   * @param event
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {
    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;
    this.loadData();
  }

  /**
   * Set the column filter and reaload the daaa
   * @param columFilter
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *
   */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
   * On reward-category selected
   * @param rewardCategoryInfo
   */
  onRowSelected(orderInfo: OrderModel): void {
    this.selectedItem = orderInfo;
    this.showRewardCategorySummary = true;
  }

  /**
   * Creates the column filter conditions based on search criteria
   * @returns Filter condions for columns
   */
  getSearchParam(): SearchParam {
    return this.searchColumFilter;
  }
}
