<app-lucky-draw-menu> </app-lucky-draw-menu>
<div class="reward-component-container  page-root-container">
    <div class="page-header">
      <!-- <button mat-button class="nav-button" (click)="navBack();" >
        <mat-icon>arrow_back</mat-icon>
</button> -->
    
      
      <div class="page-title">
        <img src="assets/images/menu/svg/winners.svg" style="height: 24px;">
  
        <span>WINNERS</span> <span>- 40th Anniversary Draw (Week-4)</span>
        <!-- <span>Draw</span> -->
      </div>
      
      <app-lucky-draw-winner-action-selection-list  (winnerStatusApplied)="onStatusApplied($event)" *ngIf="getCurPageSelectedCusts().length>0 && publishValue==0"></app-lucky-draw-winner-action-selection-list>
      @if(publishValue==0){
        <button mat-raised-button  class="Publish-button"(click)="publishWinner()" >
          Publish
        </button>
      }
      @if(publishValue==1){
        <button mat-raised-button  class="Published-button" (click)="onPublished()">
         Published
   </button>
      }
      
      
      @if(publishValue==2){
        <!-- <button mat-button  class="Publishing-button" (click)="onPublishing()" >
          <span>Publishing</span>
          <div  class="loader"></div>
   </button> -->
   <button mat-button  class="Publishing-button" (click)="onPublishing()" >
    <div class="publishing-div">
      <span class="button-text">Publishing</span>
    <div  class="loader"></div>
    </div> 
</button>
  
      }
      
      
      
      
     
      <div class="page-actions">    
        @if(permission.can_export){
        <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
      [disabled]="IsDownloading">
      <img class="download-icon"
              [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
      Export
</button>
        }
      </div>
     
    </div>
    <div *ngIf="publishValue==1" id="notification" class="notification"
    style="text-align: center;margin-bottom:10px; background: yellow; color:red; padding:15px;">
    This Draw has already been published. You cannot edit anymore.
  </div>
    <div class="data-container">
      <app-loader [show]="isLoading"></app-loader>
  
      
      <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"   [filter]="filter"  (page)="onePageEvent($event)"  [hidePageSize]="true" (onApply)="onFilterApplied($event)" [hideAdvancedFilter]="hideAdvancedFilter" >
      </app-search-filter>

      <div [style.height]="selection.selected.length>0 ? '60px' : '0px' " class="notification-bar">

        <span class="notification">{{getSelectionNotificationMsg()}}</span>
        <!-- <button mat-flat-button *ngIf="selection.selected.length!==pagingData.length">Select All</button> -->
</div>
      <section tabindex="0" class="table-container">
        <!-- <table matSort mat-table [dataSource]="dataSource">
        
          <ng-container matColumnDef="selectMenu">
                  <th mat-header-cell *matHeaderCellDef style="width: 26px;  padding: 0px;"
                          [attr.rowspan]="2">
                          <a mat-icon-button class="row-selection-menu"
                                 >
                                  <mat-icon>more_vert</mat-icon>
                          </a>
                          
                  </th>
                  <td mat-cell *matCellDef="let row" style="width: 26px;"></td>
          </ng-container>

        
          <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width: 30px; padding: 0px;"
                          [attr.rowspan]="2">
                          <mat-checkbox >
                          </mat-checkbox>

                  </th>
                   <td mat-cell *matCellDef="let row" style="width: 30px; padding: 0px;">
                          <mat-checkbox >
                          </mat-checkbox>
                  </td>
          </ng-container>
          <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let customer; let i = index"></td>
          </ng-container>
          <ng-container matColumnDef="customer">
                  <th mat-sort-header="customer_name" mat-header-cell *matHeaderCellDef
                          [attr.rowspan]="2" style="min-width:100px;">
                          Customer</th>
                  <td mat-cell *matCellDef="let winner" style="min-width:100px;">
                          <div class="col-customer">
                            <span class="cust-name">{{winner.customer_name}}</span>
                            <span class="cust-email">{{winner.customer_mail }}</span>
                          </div>
                  </td>
          </ng-container>
         
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          

     
          
          <tr mat-row class="table-element-row">
          </tr>

  </table>  -->
  

    <table matSort mat-table [dataSource]="dataSource" >
      <ng-container matColumnDef="selectMenu">
        <th mat-header-cell *matHeaderCellDef style="width: 26px;  padding: 0px;"
                [attr.rowspan]="2">
                <a mat-icon-button class="row-selection-menu"
                [matMenuTriggerFor]="selectionMenu" >
                        <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #selectionMenu>
                  <button mat-menu-item (click)="toglleDisplaySelected()">   
                          {{showSelectedCustomers?"Show all":"ShowSelected Only"}}
                  </button>
                  <button mat-menu-item (click)="clearAllSelection()">Clear All
                          Section</button>
               </mat-menu>
                
        </th>
        <td mat-cell *matCellDef="let row" style="width: 26px;"></td>
</ng-container>
<ng-container matColumnDef="select">
  <th mat-header-cell *matHeaderCellDef style="width: 30px; padding: 0px;"
          [attr.rowspan]="2">
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
  </mat-checkbox>
  </th>
   <td mat-cell *matCellDef="let row" style="width: 30px; padding: 0px;">
        
    <mat-checkbox (click)="$event.stopPropagation()"
    (change)="$event ? selection.toggle(row.id) : null"
    [checked]="selection.isSelected(row.id)">
</mat-checkbox>
  </td>
</ng-container>
<ng-container matColumnDef="item">
  <th mat-header-cell *matHeaderCellDef  (click)="sortData($event)">#</th>
  <td mat-cell *matCellDef="let winner; let i = index">{{
          (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
</ng-container>
<ng-container matColumnDef="customer_name">
  <th mat-sort-header="customer_name" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
          [attr.rowspan]="2" style="min-width:100px;">
          Customer</th>
  <td mat-cell *matCellDef="let winner" style="min-width:100px;">
          <div class="col-customer">
                  <span class="cust-name">{{winner.customer_name}}</span>
                  <span class="cust-email">{{winner.customer_mail }}</span>
          </div>
  </td>
</ng-container>
      

      <!-- <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let winner"> {{ winner.customer_name }} </td>
      </ng-container>
  

      
      <ng-container matColumnDef="customer_mail">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let winner"> {{ winner.customer_mail }} </td>
      </ng-container> -->
  
      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-sort-header="phone" mat-header-cell *matHeaderCellDef
         [attr.rowspan]="2" (click)="sortData($event)"
          >Phone</th>
        <!-- <th mat-header-cell *matHeaderCellDef  [attr.rowspan]="2" > Phone </th> -->
        <td mat-cell *matCellDef="let winner"> {{ winner.customer_phone }} </td>
      </ng-container>
  
      <!-- Member Column -->
      <!-- <ng-container matColumnDef="customer_type">
        <th mat-header-cell *matHeaderCellDef> Member </th>
        <td mat-cell *matCellDef="let winner"> {{ winner.customer_type === 1 ? 'Yes' : 'No' }} </td>
      </ng-container> -->
  
      <!-- Receipt Number Column -->
      <ng-container matColumnDef="receipt_number">
        <th mat-sort-header="receipt_number" mat-header-cell *matHeaderCellDef
          [attr.rowspan]="2" (click)="sortData($event)"> Receipt # </th>
        <td mat-cell *matCellDef="let winner"> {{ winner.receipt_number }} </td>
      </ng-container>
  
      <!-- Prize Column -->
      <ng-container matColumnDef="reward_name">
        <th mat-sort-header="reward_name" mat-header-cell *matHeaderCellDef [attr.rowspan]="2" (click)="sortData($event)"> Prize </th>
        <td mat-cell *matCellDef="let winner"> {{ winner.reward_name }} </td>
      </ng-container>
      <ng-container matColumnDef="is_valid">
        <th  mat-sort-header="is_valid"mat-header-cell *matHeaderCellDef [attr.rowspan]="2"(click)="sortData($event)"> Status </th>
        <td mat-cell *matCellDef="let winner">
          @if(winner.winner_status === 1){
            <div class="status Included">
            Included
            </div>
            }
           
            @if(winner.winner_status === 0){
            <div class="status Excluded">
            Excluded
            </div>
            } </td>
      </ng-container>
  
      <!-- Table Header and Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
      <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns"
      [ngClass]="{ 'row-selected': item.id === selectedItem?.id }"></tr>

  
    </table>

  

  
  
        <!-- <div>
            <table>
              <thead>
                <tr>
                  <th>name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>menber</th>
                  <th>Receipt Number</th>
                  <th>prize</th>
                  <th>Status</th>
              
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let winner of data">
                  <td>{{ winner.customer_name }}</td>
                  <td>{{ winner.customer_mail }}</td>
                  <td>{{ winner.customer_phone }}</td>
                  <td>{{ winner.customer_type === 1 ? 'Yes' : 'No' }}</td>
                  <td>{{ winner.receipt_number }}</td>
                
                </tr>
              </tbody>
            </table>
          </div> -->
          
          <!-- <ng-template #noData>
            <p>No winners available</p>
          </ng-template> -->
          
      </section>
      @if(pagingData.length<=0){ <div class="no-data-container">
        <img src="assets/images/no-data.png" />
        <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
    </div>
    }
  </div>
  </div>
