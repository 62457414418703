<app-lucky-draw-menu> </app-lucky-draw-menu>

<div class="reward-component-container  page-root-container">
  <div class="page-header">
    <!-- <div class="page-title">Promotion</div> -->
    <div class="page-title">
      <img src="assets/images/menu/svg/draw.svg" style="height: 24px;">

      <span>Draws</span>
      <!-- <span>Draw</span> -->
    </div>
    <div class="page-actions">
      @if(permission.can_add){
        <button mat-button color="primary" class="btn-add" (click)="addClicked()">
        <div style="display: flex; gap: 5px; align-items: center;">
          <img style="height: 16px;" src="assets/images/icons/plus.svg"> <span>New</span>
        </div>
      </button>
    }      
    @if(permission.can_export){

      <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
      [disabled]="IsDownloading">
      <img class="download-icon"
              [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
      Export
</button>
    }
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>
    <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
    </app-search-filter>

    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td mat-cell *matCellDef="let element; let i = index" class="item-cloum">{{
            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-sort-header="code" mat-header-cell *matHeaderCellDef (click)="sortData($event)" style="width: 250px;">
            Code
          </th>
          <td mat-cell *matCellDef="let item">{{ item.code}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Name
          </th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-sort-header="status" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="status-header">
            Status
          </th>
          <td mat-cell *matCellDef="let item" class="status-column">
            <div class="reward-active-status">
              @if(item.status == 1){
              <div class="status unpublished">
                Ready <span></span>
              </div>
              }@else if(item.status == 2){
              <div class="status expired">
                Generated
              </div>
              }
              @else if(item.status == 3){
              <div class="status active">
                Published
              </div>
              }
            </div>
          </td>
        </ng-container>
       
        <ng-container matColumnDef="date_from">
          <th mat-sort-header="date_from" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            Start Date
          </th>
          <td mat-cell *matCellDef="let item" class="start-date-column">
            <!-- {{ getUpdatedDate(item) }} -->
            {{ item.date_from | date: dateFormat }}

          </td>
        </ng-container>
        <ng-container matColumnDef="date_to">
          <th mat-sort-header="date_to" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="end-date-header">
            End Date
          </th>
          <td mat-cell *matCellDef="let item" class="end-date-column">
            @if(item.valid_for!= 0){
            {{ item.date_to | date: dateFormat }}


            }
            @else{

            }
          </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="row-menu-btn" style="width: 50px" *matCellDef="let rowItem">
            @if(permission.can_delete && permission.can_edit){
            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
              (click)="onRowSelected(rowItem); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </a>
          }
            <mat-menu #rowMenu>
              @if(permission.can_delete){
              <button mat-menu-item (click)="onDelete(rowItem)">
                Delete
              </button>
              }
              @if(permission.can_edit){
              <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
              }
              @if(permission.can_execute && rowItem.generated_at === null && rowItem.is_published == 0){
                <button mat-menu-item (click)="generate(rowItem)">Generate</button>
              }
              @if(permission.can_execute && rowItem.generated_at != null){
                <button mat-menu-item (click)="navigateToWinner(rowItem)">Winners</button>
              }
              @if(permission.can_execute && rowItem.generated_at != null && rowItem.is_published == 0){
                <button mat-menu-item (click)="reGenerate(rowItem)">Re-Generate</button>
                <button mat-menu-item (click)="publish(rowItem)">Publish</button>
                }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns"
          (click)="onRowSelected(item)" (dblclick)="onEdit(item)"
          [ngClass]="{ 'row-selected': item.id === selectedItem?.id }"></tr>
      </table>
    </section>
    @if(pagingData.length<=0){ <div class="no-data-container">
      <img src="assets/images/no-data.png" />
      <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
  </div>
  }
</div>
</div>
<!-- <app-Luckydraw-summary [reward]="selectedItem" (onEdit)="onEdit($event)"
  (onDelete)="onDelete($event)"></app-Luckydraw-summary> -->
